import React from 'react'
import ReactMarkdown from "react-markdown"
import { graphql } from 'gatsby'
import TopMenu from '../components/topMenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
import Clock from '../components/view/clock'
import Drawing from '../components/view/drawing'
import Apple from '../components/view/apple'
import Sun from '../components/view/sun'
import Lesson from '../components/lesson'

const ScheduleTemplate = ({ data, pageContext }) => {
    // console.log("ScheduleTemplate: ", data);
  const { frontmatter: content } = data.content
  const slug = content.slug;
  const weekdays = data.classes.childMdx.frontmatter.weekdays;

  return (
    <section className={"page vp-lang-" + pageContext.language}>
      <SEO
        title="Home"
        description={content.description}
      />
      <TopMenu smallLogo={true} lang={content.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation}></TopMenu>
      <div className="container-1200">
        <div className="section vp-albums-in">

        <div className="columns aic mb100">
          <div className="column-50 posr mr2">
            <h1 className="color-accent-2">{content.title}</h1>
            <ReactMarkdown source={content.content} linkTarget="_blank"/>
          </div>
          <div className="column-50">
              <div className="color-accent-2" style={{maxWidth: "200px"}}>
              <Drawing immediate={true} direction="top-right">
                <Clock/>
              </Drawing>
              </div>
          </div>
        </div>

        <div className="vp-time-table__body columns posr">
              <span className="vp-time-table-drawing__apple color-accent-1">
                <Drawing><Apple></Apple></Drawing>
              </span>
            {
              weekdays.map((day, i) => (
                <div key={i} className="vp-time-table__day mb2">
                  <h3>{pageContext.translation.schedule[day.title.toLowerCase()]}</h3>
                  <ul className="vp-time-table-lessons">
                    {
                     day.lessons.map((lesson, l) => <Lesson key={l} translation={pageContext.translation} {...lesson}></Lesson>)
                    }
                  </ul>
                </div>
              ))
            }
        </div>
        <div className="tac">
            <div className="vp-time-table-drawing__sun color-accent-2">
              <Drawing direction="top-left"><Sun></Sun></Drawing>
            </div>
        </div>
        </div>
      </div>
      <Footer lang={content.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default ScheduleTemplate

export const indexQuery = graphql`
  query ScheduleById($id: String!) {
    content: mdx(
      id: { eq: $id }
    ) {
      frontmatter {
        slug
        language
        title
        description
        content
      }
    }
    classes: file(sourceInstanceName: {eq: "settings"}, name: {eq: "classes"}) {
      childMdx {
        frontmatter {
          weekdays {
            title
            lessons {
              category
              language
              time
            }
          }
        }
      }
    }
    
  }
`